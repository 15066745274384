import React from 'react'
import { experiences } from '../../data'
import Domaine from '../templates/Domaine'

const ExperienceProfessionnelle = ({ col }) =>

  <>
    <Domaine col={col} name='Expérience professionnelle' />
    {experiences.length > 0 && experiences.map(({ date, ville, entreprise, poste, realisation }, i) =>
      <div className='row' key={i}>
        <div className={col.left}>
          <p className='inline'>{date}</p>
          <p className='inline'>{ville}</p>
        </div>
        <div className={col.right}>
          <p className='row'>
            <div className='col px-0'>
              <span className='d-inline'>
                <h3 className='d-inline h4'>{entreprise}</h3>
                <span className='d-inline '> | </span>
                <p className='d-inline bg-orange'>{poste}</p>
              </span>
            </div>
          </p>
          <p className='row orange-text text-darken-1'>Tâches réalisées</p>
          {realisation.length > 0 && <ul>{realisation.map((r, i) =>
            <p className='pr-3 d-inline row' key={i}>
              <li className='col col-1 fas fa-angle-double-right py-1' />
              <li className='col col-11'>{r}</li>
            </p>
          )}
                                     </ul>}
        </div>

      </div>
    )}
  </>

export default ExperienceProfessionnelle
