import React from 'react'
// import { jsPDF } from 'jspdf'
import Profil from '../components/Profil'

// const addPDF = (text) => {
//     const doc = new jsPDF()
//     doc.text(text, 10, 10)
//     doc.save('cv-olivier-mayeur.pdf')
// }

const HomePage = () => <Profil />

export default HomePage
