import React from 'react'
import { competences } from '../../data'
import Domaine from '../templates/Domaine'

const Competences = ({ col }) =>

  <>
    <Domaine col={col} name='Compétences informatiques' />
    {competences.length > 0 && competences.map(({ comp }, i) =>
      <div className='row' key={i}>
        <div className={col.left} />
        <div className={col.right}>
          <p className='row'>
            <div className='col px-0'>
              {comp.length > 0 &&
                <ul>{comp.map((r, i) =>
                  <p className='pr-3 d-inline row' key={i}>
                    <li className='col col-1 fas fa-angle-double-right py-1' />
                    <li className='col col-11'>{r}</li>
                  </p>)}
                </ul>}
            </div>
          </p>
        </div>

      </div>
    )}
  </>

export default Competences
