import React, { Component } from 'react'

export default class Footer extends Component {
  render () {
    return (
      <div className='full-container orange'>
        <div className='row'>
          <div className='col-6 text-white m-5'>
            Dernière modification : le lundi 24 janvier 2022
          </div>
          <div className='col-6' />
        </div>
      </div>
    )
  }
}
