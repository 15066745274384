import React from 'react'
import imageProfil from '../../images/olivier-photo.png'
import { contact } from '../../data'

const Introduction = () =>
  <div className='full-container orange'>
    <div className='row'>
      <div className='col-6 col-md-3 text-center'>
        <img
          className='img-fluid m-xs-4 my-3 mt-xs img-thumbnail'
          src={imageProfil}
          alt='olivier mayeur'
          style={styles.image}
        />
      </div>
      <div className='col-6 col-md-4 pt-5 text-white'>
        <h1 className='h1' style={styles.description.nom}>Olivier Mayeur</h1>
        <p><strong className='text-uppercase'>Apprenti / Alternant</strong></p>
        <p><strong>29 ans</strong></p>
      </div>
      <div className='col-12 col-md-5 text-white pt-5 align-center'>
        <ul className='pt-3'>
          {contact.map(({ classe, href, text }, i) =>
            <div className='row' key={i}>
              <div className='col-2 sm-2'>
                <i className={classe} />
              </div>
              <div className='col-10 sm-10'>
                <a className='text-white' href={href}>{text}</a>
              </div>
            </div>
          )}
        </ul>
      </div>
    </div>
  </div>

const styles = {
  image: {
    width: 170
  },
  description: {
    nom: {
      marginBottom: 20
    }
  },
  bloc1: {

  }
}

export default Introduction
