import React from 'react'
import { formations } from '../../data'
import Domaine from '../templates/Domaine'

const Formation = ({ col }) =>
  <>
    <Domaine col={col} name='Formations' />
    {formations.length > 0 && formations.map(({ date, ecole, intitule, niveau, realisation }, i) =>
      <div className='row' key={i}>
        <div className={col.left}>
          <p>{date}</p>
          <p>{ecole.ville}</p>
        </div>
        <div className={col.right}>
          <p className='row'>
            <div className='col px-0'>
              <span className='d-inline'>
                <h3 className='d-inline h4'>{intitule}</h3>
                <p className='d-inline bg-orange' style={{ marginLeft: 20 }}>{ niveau ? `(${niveau})` : '' }</p>
              </span>
            </div>
          </p>
          <p className='row orange-text text-darken-1'>{ecole.nom}</p>
        </div>

      </div>
    )}
  </>

export default Formation
