
export const contact = [
  {
    classe: 'fas fa-phone-alt white-text ml-1 text-right',
    href: 'tel:+33662615719',
    text: '06 62 61 57 19'
  },
  {
    classe: 'fas fa-envelope',
    href: 'mailto:contact@olivier-mayeur.com',
    text: 'contact@olivier-mayeur.com'
  },
  {
    classe: 'fas fa-map-marker-alt',
    href: '',
    text: 'Dolus, France'
  }
]

export const experiences = [
  {
    date: 'Mars 2021 - Janvier 2022',
    ville: "Saint Pierre d'Oléron",
    entreprise: 'Océan Digital',
    poste: 'Responsable production & support',
    realisation: [
      "Création et mise en place d'une extension d'enchère inversée",
      'Réalisation de sites internet pour les clients',
      'Suivi des projets et du support',
      "Création d'une extension de gestion des consommations d'un bar",
      'Former les clients'
    ]
  }
]

export const formations = [
  {
    intitule: 'Concepteur Développeur Web',
    niveau: 'BAC +3',
    date: 'Commencée en 2020',
    ecole: {
      nom: '3W Academy',
      ville: 'Ecole en ligne',
      description: "Commencé en décembre 2019. Toujours en cours jusqu'au 28 février 2023"
    }
  },
  {
    intitule: 'Etudes philosophiques et théologiques',
    niveau: '',
    date: '2015 - 2019',
    ecole: {
      nom: 'Séminaire de Bayonne',
      ville: 'Bayonne',
      description: '',
    }
  },
  {
    intitule: 'BTS S.I.O option S.L.A.M',
    niveau: 'BAC +2',
    date: '2013 - 2015',
    ecole: {
      nom: 'Lycée Merleau-Ponty',
      ville: 'Rochefort',
      description: 'Obtenu en 2015'
    }
  }
]

export const competences = [
  {
    type: 'informatiques',
    comp: ['JS', 'React', 'NodeJS', 'PhP', 'WordPress', 'Elementor']
  }
]

export const loisirs = ['Orgue', 'Projet']
