import './App.css'
import MainNavigation from './MainNavigation'

function App () {
  return (
    <MainNavigation />
  )
}

export default App
