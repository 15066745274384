import React from 'react'
import Introduction from '../components/profil/Introduction'
import Competences from './profil/Competences'
import ExperienceProfessionnelle from './profil/ExperienceProfessionnelle'
import Footer from './profil/Footer'
import Formation from './profil/Formation'
import Loisirs from './profil/Loisirs'

const col = {
  left: 'col-12 col-sm-3 col-md-4',
  right: 'col-12 col-sm-9 col-md-8'
}

const Profil = ({ contact, experiences, formations, competences, loisirs }) => {
  return (
    <>
      <Introduction />
      <div className='full-container my-5'>
        <div className='container'>
          <ExperienceProfessionnelle col={col} {...experiences} />
          <Formation col={col} {...formations} />
          <Competences col={col} {...competences} />
          <Loisirs col={col} {...loisirs} />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Profil
