import React from 'react'
import Domaine from '../templates/Domaine'

const loisirs = ['Orgue', 'Chant']

const Loisirs = ({ col, name }) => {
  return (
    <>
      <Domaine col={col} name='Loisirs' />
      {loisirs.length > 0 &&
        <div className='row'>
          <div className={col.left} />
          <div className={col.right}>
            <p className='row'>
              <div className='col px-0'>
                <ul>
                  {loisirs.map((loisir, i) =>
                    <p className='pr-3 d-inline row' key={i}>
                      <li className='col col-1 fas fa-angle-double-right py-1' />
                      <li className='col col-11'>{loisir}</li>
                    </p>
                  )}
                </ul>
              </div>
            </p>
          </div>
        </div>}
    </>
  )
}

export default Loisirs
