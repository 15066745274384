import React from 'react'

const Domaine = ({ col, name }) => {
  return (
    <div className='row'>
      <div className={col.left + ' order-2 order-sm-1'}>
        <div className=''>
          <div className='divider valign-wrapper mt-3 orange' />
        </div>
      </div>
      <div className={col.right + ' order-1 order-sm-2'}>
        <div className='text-uppercase h3 orange-text text-darken-1'>
          {name}
        </div>
      </div>
    </div>
  )
}

export default Domaine
